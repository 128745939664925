<template>
  <div class="Login">
    <div class="login-box">
      <div class="login-header bg-secondary">Login</div>
      <div class="login-body py-4">
        <div class="login-container">
          <div class="form-group mb-3 ">
            <label for="email">帳號</label>
            <input
              id="email"
              type="text"
              class="form-control"
              placeholder="Email"
              aria-label="email"
              v-model="email"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="form-group mb-3 ">
            <label for="Password">密碼</label>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              aria-label="Password"
              v-model="password"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="btn btn-outline-primary w-100 mb-3" @click="login">
            登入
          </div>
          <div v-if="status" class="text-center mb-3 text-danger font-bold">
            {{ status }}
          </div>
          <ul class="d-flex justify-content-center">
            <li class="px-2">
              <router-link to="/register">會員註冊</router-link>
            </li>
            <li class="px-2"><a href="">忘記密碼</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: null,
      password: null,
      status: "",
    };
  },
  methods: {
    login() {
      if (!this.email || !this.password) {
        this.status = "帳號密碼不得為空";
        return;
      }
      let params = {
        email: this.email,
        password: this.password,
      };
      this.axios.post("http://localhost:3333/login", params).then((res) => {
        console.log(res.data);
        if (res.data.result.status === "登入失敗") {
          this.status = res.data.result.err;
          // localStorage.setItem("token", res.data.token);
          // this.$store.dispatch("SET_TOKEN", res.data.token);
          // this.$router.push("/");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Login {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-header {
  color: white;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.login-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-container {
  width: 80%;
}
.login-box {
  width: 80%;
  background-color: white;
  // height: 300px;
  border-radius: 5px;
  border: 1px solid gray;
  /* box-shadow: 3px 1px 1px 3px white; */
  @media (min-width: 576px) {
    width: 60%;
  }
}
.btn-outline-secondary {
  background-color: #212529;
}
</style>
