<template>
  <div class="container">
    <div class="header">
      <div
        class="banner"
        :style="`background-image:url(${require('@/assets/game_img/banner.png')})`"
      >
        <div class="banner-blur">
          <div class="row">
            <div
              class="
                about
                text-center text-sm-left
                col-12 col-sm-6
                d-flex
                flex-column
                justify-content-center
              "
            >
              <!-- <div class="title">關於我們</div>
              <div class="subtitle">
                CFD為廣告天下成立的遊戲新團隊品牌，致力於Facebook，Instagram等社群經營，近年加入社群遊戲的開發行列，以HTML為主軸開發遊戲並協助客戶以取代傳統Flash
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content mb-5">
      <div class="all-game py-3">ALL GAMES</div>
      <div class="game-list my-3">
        <div class="game-list-container row">
          <div
            class="game-card-container col-6 col-sm-4 col-md-3 col-lg-2"
            v-for="item in game_list"
            :key="item.id"
          >
            <div class="game-card mb-3" :class="{ coming: !item.canPlay }">
              <div
                v-if="item.canPlay"
                class="game-img"
                :style="`background-image:url(${item.pic})`"
                @click="choose(item)"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <div
                  class="blur d-flex justify-content-center align-items-center"
                >
                  <div class="w-75 know btn btn-outline-secondary">
                    了解更多
                  </div>
                </div>
              </div>
              <div
                v-else
                class="game-img"
                :style="`background-image:url(${item.pic})`"
              >
                <div
                  class="blur d-flex justify-content-center align-items-center"
                >
                  <div class="w-75 know btn btn-outline-secondary">
                    敬請期待
                  </div>
                </div>
              </div>
              <div class="game-info">{{ item.name }}</div>
              <div class="html-logo d-flex justify-content-between">
                <span>{{ item.type }}</span>
                <img
                  width="20"
                  height="20"
                  :src="require(`@/assets/${item.platform}_logo.png`)"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ choose_game.name }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex mb-3">
              <img class="w-50" :src="choose_game.pic" alt="" />
              <p class="mx-3 mb-3 text-primary">
                {{ choose_game.content }}
              </p>
            </div>
            <a class="btn btn-outline-primary w-100" :href="choose_game.url"
              >Play</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      game_list: [
        {
          id: 9,
          platform: "android",
          name: "牛氣沖天",
          pic: require("@/assets/game_img/cow.png"),
          content: `牛氣沖天是一款射擊類游戲。玩家點擊屏幕牛向前跑。玩家的目標是讓牛順利的通過柵欄，跑向鬥牛士。如果牛撞到了柵欄則遊戲結束。`,
          url: "https://play.google.com/store/apps/details?id=org.cocos2d.Bullfight_MD_WS",
          type: "休閒",
          canPlay: true,
        },
        {
          id: 10,
          platform: "android",
          name: "貓和老鼠",
          pic: require("@/assets/game_img/cat.png"),
          content: `貓和老鼠是一款休閒的射擊類游戲，玩家可以帶著放鬆的心情來玩，感受射擊遊戲的魅力。玩法：玩家滑動屏幕控制叉子移動，鬆開手後叉子會做拋物線運動並擊飛前來偷奶酪的老鼠。
這款遊戲簡單有趣，面向每一個智能手機用戶，不需要任何遊戲經驗都可以上手玩，輕輕滑動屏幕就可以感受。`,
          url: "https://play.google.com/store/apps/details?id=org.cocos2d.CatAndMouse",
          type: "休閒",
          canPlay: true,
        },
        {
          id: 1,
          platform: "android",
          name: "烏鴉喝水",
          pic: require("@/assets/game_img/ThirstyBird.png"),
          content:
            "《烏鴉喝水》是一個具有挑戰性的休閒遊戲。玩家可以點擊任意位置使烏鴉投放石頭，同時烏鴉加速飛走並生成新的烏鴉，石頭落進瓶子裡獲得分數。大多數玩家對這款遊戲的評價是簡單且容易上手，有趣而充滿挑戰。",
          url: "https://play.google.com/store/apps/details?id=org.cocos2d.ThirstyBird",
          type: "休閒",
          canPlay: true,
        },
        {
          id: 2,
          platform: "android",
          name: "合成大西瓜",
          pic: require("@/assets/game_img/Watermelon.png"),
          content:
            "最近超級火爆的小遊戲《合成大西瓜》，暢快淋漓的手感， 玩家點擊屏幕上的任意位置以放置水果。當兩個相同的水果碰到一起時，他們會合成為更高等級的水果，通過不斷合成最終會合成大西瓜， 水果超過屏幕頂部的境界線則遊戲結束。",
          url: "https://play.google.com/store/apps/details?id=org.cocos2d.HCDXGbate1",
          type: "休閒",
          canPlay: true,
        },
        {
          id: 3,
          platform: "android",
          name: "丘比特之箭",
          pic: require("@/assets/game_img/Arrow.png"),
          content: `《丘比特之箭》是一款休閒的射擊類游戲，玩家可以帶著放鬆的心情來玩，感受射擊遊戲的魅力。
玩法：玩家點擊屏幕丘比特會射出一支箭，箭碰到紅色的心會射穿它，如果連續射中兩個以上紅心會觸發一箭 雙心效果，如果射中黑色心則遊戲結束。`,
          url: "https://play.google.com/store/apps/details?id=org.cocos2d.CupidArrow",
          type: "休閒",
          canPlay: true,
        },
        // {
        //   id: 9,
        //   platform: "android",
        //   name: "Connector",
        //   pic: require("@/assets/game_img/Connector.png"),
        //   content: `益智遊戲Connector上線啦！翻動管線板塊，讓燈泡發亮吧。多達200關卡，挑戰你的解謎極限！趣味玩法，跳脫邏輯激發想像！`,
        //   url:
        //     "https://play.google.com/store/apps/details?id=com.wasichris.toolset",
        //   type: "益智",
        //   canPlay: true,
        // },
        // {
        //   id: 10,
        //   platform: "android",
        //   name: "Omicronian",
        //   pic: require("@/assets/game_img/Omicronian.png"),
        //   content:
        //     "射擊遊戲Omicronian上線啦！躲避敵人攻擊，守衛你的星球吧！趣味玩法，挑戰各式各樣的敵人！多道關卡等你解鎖，挑戰你的手速極限！",
        //   url:
        //     "https://play.google.com/store/apps/details?id=com.wasichris.toolsets",
        //   type: "射擊",
        //   canPlay: true,
        // },
        // {
        //   id: 1,
        //   platform: "html",
        //   name: "DefendTheDen",
        //   pic: require("@/assets/game_img/DefendTheDen.png"),
        //   content:
        //     "野豬大軍襲來，你將扮演狼戰士抵禦牠們的攻擊，善用魔法與魔力清除牠們，以保衛家族的榮耀!",
        //   url: "https://game.cfd.com.tw/play/set/legendofwolf/",
        //   type: "守城",
        //   canPlay: true,
        // },
        // {
        //   id: 2,
        //   platform: "html",
        //   name: "Kite",
        //   pic: require("@/assets/game_img/kite.png"),
        //   content:
        //     "身為風神，你將引導風箏前往更高的地方，無情的樹葉將會阻撓你，你能夠通過幾關呢?",
        //   url: "https://game.cfd.com.tw/play/set/kite/",
        //   type: "休閒",
        //   canPlay: true,
        // },
        // {
        //   id: 3,
        //   platform: "html",
        //   name: "Minorminer",
        //   pic: require("@/assets/game_img/minorminer.png"),
        //   content:
        //     "小小礦工這次要挑戰最神秘的地下世界，請留意腳步，一不小心將死在地下陷阱之中",
        //   url: "https://game.cfd.com.tw/play/set/minorminer/",
        //   type: "動作",
        //   canPlay: true,
        // },
        {
          id: 4,
          platform: "html",
          name: "MonsterAndCandy",
          pic: require("@/assets/game_img/MonsterAndCandy.png"),
          content:
            "歐姆是一隻貪吃的小惡魔，幫助他吃掉世間所有的糖果，千萬不要讓糖果掉到地上，否則人間將會產生一場災厄!",
          url: "https://game.cfd.com.tw/play/Monster-And-Candy/",
          type: "休閒",
          canPlay: true,
        },
        {
          id: 5,
          platform: "html",
          name: "RunRobotRun",
          pic: require("@/assets/game_img/RunRobotRun.png"),
          content:
            "科學家創造的機器人失控了，它無法停下自己的腳步，快來幫忙它，壞掉了你可賠不起!",
          url: "https://game.cfd.com.tw/play/set/runrobot/",
          type: "動作",
          canPlay: true,
        },
        {
          id: 6,
          platform: "html",
          name: "Jumpone",
          pic: require("@/assets/game_img/jumpone.png"),
          content: "考驗你的靈敏度，你會知道它彈多遠嗎? 我也不知道",
          url: "https://game.cfd.com.tw/play/set/jumpone/",
          type: "休閒",
          canPlay: true,
        },
        {
          id: 7,
          platform: "html",
          name: "Tacit",
          pic: require("@/assets/game_img/Tacit.png"),
          content:
            "這是一個合作遊戲，和你的好友一起使用魔法元素消除陷阱，希望他不要笨手笨腳的。",
          url: "https://game.cfd.com.tw/play/set/tacit/",
          type: "節奏",
          canPlay: true,
        },
        {
          id: 8,
          platform: "html",
          name: "Timberpig",
          pic: require("@/assets/game_img/Timberpig.png"),
          content:
            "三隻小豬的老二要砍樹蓋房子了，快來幫牠砍樹! 希望不要被大野狼吃掉之前先被樹枝砸死?",
          url: "https://game.cfd.com.tw/play/set/timberpig/",
          type: "休閒",
          canPlay: true,
        },
      ],
      choose_game: {
        name: null,
        pic: null,
        content: null,
        url: null,
      },
    };
  },
  methods: {
    choose(game) {
      this.choose_game = game;
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  background-size: cover;
  .about {
    height: 353px;
  }
  .title {
    font-size: 32px;
    color: white;
    font-weight: bold;
  }
  .subtitle {
    color: white;
  }
}
.banner-blur {
  background-color: rgba(0, 0, 0, 0.5);
}
.game-img {
  cursor: pointer;
  border-radius: 4px;
  height: 226px;
  background-size: cover;
  background-position: center;
  .know {
    color: white;
    transition: all 0.5s;
    &:hover {
      border: 1px solid white;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  .blur {
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    height: 100%;
    width: 100%;
    transition: all 0.5s;
  }
  &:hover {
    .blur {
      opacity: 1;
    }
  }
}
.coming {
  .game-img {
    cursor: default;
  }
  .blur {
    opacity: 1;
  }
  .know {
    cursor: default;
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.game-info {
  margin: 10px 0;
  color: white;
}
.all-game {
  font-size: 24px;
  color: white;
  font-weight: bold;
  border-bottom: 3px solid white;
}
.modal-title {
  font-size: 24px;
  color: white;
  font-weight: bold;
}
.modal-header {
  background-color: #27292c;
  border: 1px solid gray;
}
.logo {
  background-image: url("http://cfd.com.tw/images/logo.svg");
  width: 80px;
  height: 27px;
  background-size: cover;
}
</style>
