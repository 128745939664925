<template>
  <div class="Login my-5">
    <div class="login-box">
      <div class="login-header bg-secondary">Register</div>
      <div class="login-body py-4">
        <div class="login-container">
          <div class="form-group mb-3 ">
            <label for="Username">姓名</label>
            <input
              id="Username"
              type="text"
              class="form-control"
              placeholder="Username"
              aria-label="Username"
              v-model="username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="form-group mb-3 ">
            <label for="email">信箱</label>
            <input
              id="email"
              type="text"
              class="form-control"
              placeholder="Email"
              aria-label="email"
              v-model="email"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="form-group mb-3 ">
            <label for="Password">密碼</label>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              aria-label="Password"
              v-model="password"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="form-group mb-3 ">
            <label for="PasswordCheck">確認密碼</label>
            <input
              type="password"
              class="form-control"
              placeholder="PasswordCheck"
              aria-label="PasswordCheck"
              v-model="PasswordCheck"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="text-center mb-3 text-danger font-bold">{{ status }}</div>
          <div class="btn btn-outline-primary w-100 mb-3" @click="register">
            註冊
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
      PasswordCheck: "",
      status: "",
    };
  },
  methods: {
    register() {
      if (this.password == "" || this.username == "") {
        this.status = "帳密不得為空";
      } else if (this.password !== this.PasswordCheck) {
        this.status = "兩次輸入密碼不同";
      } else {
        this.status = "";
        let params = {
          name: this.username,
          email: this.email,
          password: this.password,
        };
        this.axios
          .post("http://localhost:3333/register", params)
          .then((res) => {
            console.log(res.data.result);
            if (res.data.result.status === "註冊失敗") {
              this.status = res.data.result.err;
            }else{
              this.$router.push("/login")
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Login {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-header {
  color: white;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.login-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-container {
  width: 80%;
}
.login-box {
  width: 80%;
  background-color: white;
  // height: 300px;
  border-radius: 5px;
  border: 1px solid gray;
  /* box-shadow: 3px 1px 1px 3px white; */
  @media (min-width: 576px) {
    width: 60%;
  }
}
.btn-outline-secondary {
  background-color: #212529;
}
</style>
