<template>
  <div>
    <button @click="search">搜尋</button>
  </div>
</template>
<script>
export default {
  methods: {
    search() {
      this.axios
        .post("https://api.bestaup.com/", {
          Action: "fb_search",
          data: { search: "電影" },
        })
        .then((response) => {
          console.log(response);
        });
    },
  },
};
</script>
