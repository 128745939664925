<template>
  <div class="container">
    <div class="header">
      <div
        class="banner"
        :style="`background-image:url(${require('@/assets/shap.png')})`"
      >
        <div class="banner-blur">
          <div
            class="about text-center mx-auto d-flex align-items-center justify-content-center"
          >
            <div>
              <div class="title">Countact Us</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="banner text-center h1 p-5"></div> -->
    </div>
    <div class="content my-5">
      <div class="row">
        <div class="col-md-4 col-12 mb-5">
          <div>
            <span class=" d-block text-white text-md-center">OUR OFFICES</span>
            <p class="text-md-center">
              We look forward to your cooperation proposals and suggestions
            </p>
          </div>
          <div class="mt-4">
            <span class="d-block text-white text-md-center">MAIN OFFICE </span>
            <p class="text-md-center">
              17F.-1, No. 631, Sec. 1, Chongde Rd., North Dist., Taichung City ,
              Taiwan (R.O.C.)
            </p>
          </div>
          <div class="mt-4">
            <span class="d-block text-white text-md-center">CONTACT</span>
            <a href="mailto: digitalcfs@gmail.com" class="text-md-center"
              >digitalcfs@gmail.co
            </a>
          </div>
        </div>
        <div class="col-md-8 col-12 ">
          <div class="form p-5">
            <h2 class="text-white mb-3">Countact Us</h2>
            <div v-if="isSend" class="p-2 success text-white text-center">
              Thank you! Your submission has been received!
            </div>
            <div v-else>
              <div class="form-group">
                <label class="text-white" for="exampleFormControlInput1"
                  >NAME</label
                >
                <input
                  v-model="name"
                  type="name"
                  class="form-control rounded-0"
                  placeholder="Enter your name"
                />
                <span v-if="!name_confirm" class="text-danger"
                  >Please enter your name</span
                >
              </div>
              <div class="form-group">
                <label class="text-white" for="exampleFormControlInput1"
                  >EMAIL ADDRESS</label
                >
                <input
                  v-model="email"
                  type="email"
                  class="form-control rounded-0"
                  id="exampleFormControlInput1"
                  placeholder="Enter your email"
                />
                <span v-if="!email_confirm" class="text-danger"
                  >Please enter your email</span
                >
              </div>
              <div class="form-group">
                <label class="text-white" for="exampleFormControlTextarea1"
                  >MESSAGE</label
                >
                <textarea
                  v-model="content"
                  class="form-control rounded-0"
                  id="exampleFormControlTextarea1"
                  placeholder="Hi there, I’m reaching out because I think we can collaborate…"
                  rows="3"
                ></textarea>
              </div>
              <button v-if="isWait" class="w-100 mt-4 rounded-0 btn btn-light">
                PLEASE WAIT...
              </button>
              <button
                v-else
                type="submit"
                @click="submit"
                class="w-100 mt-4 rounded-0 btn btn-outline-light"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      content: "",
      name_confirm: true,
      email_confirm: true,
      isSend: false,
      isWait: false,
    };
  },
  methods: {
    submit() {
      if (!this.name || !this.email) {
        this.name_confirm = this.name ? true : false;
        this.email_confirm = this.email ? true : false;
      } else {
        this.isWait = true;
        this.name_confirm = true;
        this.email_confirm = true;
        // setTimeout(() => {
        //   this.isSend = true;
        // }, 1000);
        this.axios
          .post("http://localhost/test.php", {
            name: this.name,
            email: this.email,
            content: this.content,
          })
          .then((res) => {
            console.log(res.data);
            setTimeout(() => {
              this.isSend = true;
            }, 1000);
          })
          .catch((err) => {
            this.name = "";
            this.email = "";
            this.content = "";
            this.isWait = false;
            console.log(err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  background-color: white;
}
.form {
  border: 1px solid white;
}
a {
  font-size: 16px;
  color: #737373;
  text-decoration: underline;
}
.success {
  background-color: #12b878;
}
</style>
