<template>
  <div>
    <h1>管理員頁面</h1>
  </div>
</template>
<script>
export default {
  created() {
    this.checkStatus();
  },
  methods: {
    checkStatus() {
      this.axios
        .post("http://127.0.0.1/test.php", {
          token: this.token,
        })
        .then((res) => {
          if (!res.data.status) {
            this.$router.push("/login").catch((err) => {});
          }
        });
    },
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
};
</script>
